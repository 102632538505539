<template>
  <b-row style="height: calc(100vh - 92px)">
    <b-col cols="12">
      <!-- Добро пожаловать в СУП Gram! -->
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol } from "bootstrap-vue";

export default {
  components: {
    BRow,
    BCol,
  },
};
</script>
